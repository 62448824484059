const en={
    jiantizhongwen: "Tiếng Anh",
    xianlu: "Đường kẻ",
    dianwei: "Điểm",
    wodezichan: "Tài sản của tôi",
    touzikuaixun: "Tin tức đầu tư",
    jiaoyiyonghu: "Người dùng",
    jiaoyipinglei: "Loại",
    danlunhuoli: "Lợi nhuận",
    jiaoyiriqi: "Ngày",
    dengluhouchakan: "Xem sau khi đăng nhập",
    shouye: "Trang chủ",
    jiaoyidating: "sảnh",
    yucun: "Prestore",
    zoushi: "Xu hướng",
    wode: "Của tôi",
    zuiditouzi: "Đầu tư tối thiểu",
    zuidijinru: "Mục nhập tối thiểu",
    jichulicai: "Tài chính cơ bản",
    zishenlicai: "Tài chính cấp cao",
    dashilicai: "Tài chính tổng thể",
    zhuanjialicai: "Chuyên gia tài trợ",
    基础理财: "Tài chính cơ bản",
    资深理财: "Tài chính cấp cao",
    大师理财: "Tài chính tổng thể",
    专家理财: "Chuyên gia tài trợ",
    huiyuanquanyi: "Lợi ích thành viên",
    zuoriyingli: "Lợi nhuận ngày hôm qua",
    zongzichan: "Tổng tài sản",
    chucunjin: "Tiền bạc",
    jinriyingli: "Lợi nhuận hôm nay",
    zhuanchu: "Rút",
    cunru: "Tiền gửi",
    zhanghuxinxni: "Thông tin tài khoản",
    lishizhangdan: "Kỷ lục cá cược",
    zijinminxi: "Chi tiết tài trợ",
    renwuzhongxin: "Trung tâm truyền giáo",
    xiaoxigonggao: "Thông báo tin tức",
    tuijianfenxiang: "Đề nghị chia sẻ",
    huodongzhongxin: "Trung tâm hoạt động",
    jibenziliao: "Thông tin cơ bản",
    dengluzhanghao: "Đăng nhập tài khoản",
    nicheng: "tên nick",
    shezhi: "Cài đặt",
    genggaixianlu: "Thay đổi dòng",
    guanyuwomen: "Về chúng tôi",
    xitongshezhi: "Cài đặt hệ thống",
    yuyanshezhi: "Cài đặt ngôn ngữ",
    tuichudenglu: "Đăng xuất",
    bangdingyinhangka: "Ràng buộc thẻ ngân hàng",
    bangdingxuniqianbao: "Liên kết ví ảo",
    anquanma: "Mã bảo mật",
    xiugailenglumima: "Sửa đổi mật khẩu đăng nhập",
    yinhangkaguanli: "Quản lý thẻ ngân hàng",
    tianjiayinhangka: "Thêm thẻ ngân hàng",
    xuniqianbaoguanli: "Quản lý ví ảo",
    tianjiaxuniqianbao: "Thêm ví ảo",
    chiakren: "Chủ thẻ",
    yinhangkakahao: "Số thẻ ngân hàng",
    yinhangmingcheng: "tên ngân hàng",
    yhkTps: "Hãy ràng buộc thẻ ngân hàng của chủ thẻ",
    nixiqianbaodizhi: "Địa chỉ ví",
    xuniqianbaoleixing: "Loại ví",
    xnqbTps: "Nhắc nhở: Vui lòng điền loại địa chỉ USDT, chẳng hạn như: trc20, erc20, omni.",
    plchikaren: "Vui lòng nhập chủ thẻ",
    plkahao: "Vui lòng nhập số thẻ chủ thẻ",
    plyinhangmingcheng: "Vui lòng nhập tên ngân hàng",
    plqbdz: "Vui lòng nhập địa chỉ ví ảo",
    plqblx: "Vui lòng nhập loại ví ảo",
    shezhianquanma: "Đặt mã bảo mật",
    yuananquanma: "Mã bảo mật gốc",
    xinanquanma: "Mã bảo mật mới",
    planquanma: "Vui lòng nhập mã bảo mật gốc",
    plxinanquanma: "Vui lòng nhập mã bảo mật mới",
    queren: "Xác nhận",
    quxiao: "Hủy bỏ",
    anquanmaTps: "Vui lòng đặt mã bảo mật, không trùng với mật khẩu thẻ ngân hàng",
    anquanmaTpsTow: "Kính gửi quý khách hàng, mã bảo mật này chính là mật khẩu khi quý khách chuyển khoản. Vì lý do bảo mật, vui lòng cố gắng không trùng với mật khẩu đăng nhập",
    xiugaidenglu: "Sửa đổi mật khẩu đăng nhập",
    jiumima: "Mật khẩu cũ",
    xinmima: "Mật khẩu mới",
    querenmima: "Xác nhận mật khẩu",
    pljiumima: "Vui lòng nhập mật khẩu cũ",
    plxinmima: "Vui lòng nhập mật khẩu mới",
    plquerenmima: "Vui lòng nhập lại mật khẩu",
    wancheng: "Hoàn thành",
    kaishitouzi: "Bắt đầu đầu tư",
    kaiTps: "Khi dòng hiện tại không khả dụng, hãy thử chuyển sang dòng khác",
    zuiyou: "Tối ưu",
    dangaqianxianlu: "Dòng hiện tại",
    dangqianbanben: "Phiên bản hiện tại",
    banquansuoyou: "Bản quyền",
    yinglizhendong: "Cú sốc thu nhập",
    gensuixitong: "Mặc định",
    zhuanchujine: "Số lượng",
    plzhuanchu: "Vui lòng nhập số tiền chuyển",
    zhuanchuTps: "Lời khuyên: Nếu bạn có bất kỳ câu hỏi nào, vui lòng liên hệ với dịch vụ khách hàng của nền tảng.",
    xuanzeyh: "Vui lòng chọn thẻ ngân hàng để rút tiền",
    xuanzehb: "Vui lòng chọn ví ảo để rút tiền",
    tijiaoshenqing: "Gửi đơn đăng ký",
    shuruanquanma: "Vui lòng nhập mã bảo mật",
    xianshangcunru: "Gửi tiền trực tuyến",
    saomacunru: "USDT",
    wangyincunru: "Tiền gửi ngân hàng",
    shijian: "Thời gian",
    upshijian: "Thời gian bắt đầu",
    downshijian: "Thời gian kết thúc",
    wuTps: "Không có dữ liệu ~",
    jiazaicx: "Tải lại",
    di: "Giai đoạn",
    lun: " ",
    weiyingli: "Không có lợi",
    yishouli: "Đã được chấp nhận",
    zhuangtai: "Trạng thái",
    chongzhi: "Cài lại",
    riqi: "Ngày",
    CapActive: {
        chongzhi: "Nạp tiền",
        tixian: "Rút tiền",
        goumai: "Mua",
        yingli: "Lợi nhuận",
        zengsong: "Cho đi",
        weitongguo: "Không thể vượt qua",
        yitongguo: "Đạt",
        yijujue: "Bị từ chối"
      },
    zhanneixiaoxi: "Thông tin",
    pingtaigonggao: "Thông báo",
    fuzhilanjie: "sao chép đường dẫn",
    denglu: "Đăng nhập",
    zhuce: "Đăng ký",
    jizhumima: "Nhớ",
    wangjimima: "Quên mật khẩu",
    youkedenglu: "Đăng nhập của khách truy cập",
    zhucezhanghao: "Đăng ký tài khoản",
    plusername: "Vui lòng nhập kết hợp từ 6 đến 16 chữ số và số",
    plpassword: "Xin vui lòng nhập mật khẩu",
    wanchengzhuce: "Hoàn thành",
    yiyouzhanghao: "Có một tài khoản",
    qingdenglu: "làm ơn đăng nhập",
    remenhuati: "Chủ đề nóng",
    news1: "Nguyên bản",
    news2: "Gợi ý",
    news3: "Tài sản",
    renliulan: "duyệt qua",
    hezhi: "Và giá trị",
    lunshu: "Giai đoạn",
    做多: "Nhiều",
    做空: "Ngắn",
    多单: "Nhiều đơn",
    多双: "Nhiều đôi",
    空单: "Đĩa đơn ngắn",
    空双: "Đôi ngắn",
    平单: "Đơn phẳng",
    平双: "phẳng đôi",
    极阴: "cực âm",
    极阳: "Cực dương",
    qi: " ",
    juli: "khoảng cách",
    lunjiezhi: "kết thúc",
    yifengpan: "Dừng cá cược",
    yiguanbi: "Đã đóng",
    yonghu: "Người dùng",
    danlunjiaoyi: "Buôn bán",
    caozuo: "Vận hành",
    pljoin: "Tham gia phòng chat thành công",
    pltingshou: "Sản phẩm đã bị ngừng sản xuất",
    gengou: "Theo",
    quedinggengou: "Bạn có chắc chắn theo dõi việc mua hàng?",
    wanjia: "người chơi",
    leibie: "loại",
    jine: "số lượng",
    gouru: "Mua",
    zanweigouru: "Chưa mua",
    qigou: "mua",
    gourushuliang: "Số lượng mua",
    changgui: "Thông thường",
    shuzi: "Con số",
    yinglihezhi: "Lợi nhuận và giá trị",
    shouyijieshao: "Thu nhập",
    lijicunru: "Tiền gửi",
    kaihaojieguo: "Ghi",
    kaihaoqushi: "Xu hướng",
    chanpingshuoming: "Sự miêu tả",
    qingchuhuancun: "xóa bộ nhớ đệm",
    youke: "Khách thăm quan",
    gongxi: "Chúc mừng",
    zai: "bên trong",
    yingli: "lợi nhuận",
    xiadanchenggong: "đặt hàng thành công",
    jiazaizhong: "Đang tải...",
    guanbitg: "đóng cửa, ngừng mua",
    xiangoudl: "Chỉ theo dõi vòng hiện tại",
    liaotiantip: "Hệ thống đã bị cấm, vui lòng chọn mua",
    tishi: "Mẹo",
    zhidao: "ĐƯỢC RỒI",
    zhuanchusb: "Không thể chuyển ra ngoài",
    zhuanchusbs: "Số dư không đủ, chuyển khoản không thành công",
    buyxq: "Chi tiết mua hàng",
    orderNo: "Hóa đơn số",
    plan_name: "Thông tin hội trường",
    buyxx: "Mua thông tin",
    haoxx: "Chi tiết lựa chọn số",
    buysj: "Cau gio",
    yilou: "mất tích",
    fzjqb: "Sao chép vào clipboard",
    chanpin1: "Thời gian bán: cứ 3,5 phút là một vòng, mở cửa lúc 10h00 cùng ngày và kết thúc lúc 02h00 ngày hôm sau.",
    chanpin2: "Mỗi vòng có 3 số từ 0 đến 9. Tổng của 3 số là kết quả cuối cùng. Bạn có thể đầu tư vào kết quả: Nhiều, Ngắn, Đơn phẳng, Đôi phẳng, Nhiều đơn, Nhiều đôi, Đơn ngắn, Đôi ngắn, Cathode, Anode và số.",
    Tips: {
        wjmm: "Quên mật khẩu, vui lòng liên hệ bộ phận chăm sóc khách hàng để sửa đổi!",
        dlcg: "Đăng nhập thành công",
        tjcg: "gửi thành công",
        zanwu: "Không có dữ liệu",
        buyizhi: "Hai mật khẩu không nhất quán",
        zcbz: "Tài sản của bạn không đủ, vui lòng gửi tiền",
        pltxje: "Vui lòng nhập số tiền rút",
        zdtx: "Rút tối thiểu 100",
        youke: "Kính gửi khách du lịch: Vui lòng đăng ký làm thành viên chính thức và hoạt động.",
        szcg: "thiết lập thành công"
        },
    chongzhix: "Nạp điện",
    game: "Trò chơi VIP",
    trading: "Sàn giao dịch",
    putongting: "Hội trường thường",
    zhongjiting: "Sảnh trung gian",
    gaojiting: "hội trường cao cấp",
    youxiwanfa: "lối chơi",
    yuex: "Sự cân bằng",
    chexiao: "Thu hồi",
    chexiaochenggong: "Đã hủy thành công",
    daxiaodanshuang: "Kích thước đơn và đôi",
    shuzitouzhu: "Cá cược kỹ thuật số",
    teshutoufa: "Phương thức đầu tư đặc biệt",
    qishu: "Giai đoạn",
    zonghe: "Tổng",
    jieguo: "Kết quả",
    dadan: "Đơn hàng lớn",
    xiaodan: "Đơn hàng nhỏ",
    dashuang: "Đại Sảng",
    xiaoshuang: "Tiểu Song",
    dax: "To lớn",
    xiaox: "Bé nhỏ",
    dan: "Đơn",
    shuang: "Gấp đôi",
    線上充值聯繫在線客服:'Nạp tiền trực tuyến liên hệ dịch vụ khách hàng trực tuyến',
    在線客服:'dịch vụ trực tuyến',
    咨询:'tham khảo ý kiến',
    gameinfo:'<span style="font-size:18px; font-weight:700; text-align:center; display:block;">Lối chơi</span>0-13 là nhỏ, 14-27 là lớn<br>Lạ và Chẵn:<br>Nếu số được rút ra là số chẵn thì gọi là số chẵn, ví dụ: 0,2,4,6,8,10<br>Số được rút ra là số lẻ, ví dụ: 1 ,3,5,7,9,11<br>Số chẵn lớn và số chẵn lớn:<br>Số ≥14 là số lớn và số chẵn, ví dụ: 14 (chẵn lớn) 15 (lẻ lớn)<br>Nhỏ đơn và đôi nhỏ:<br>Các số rút ra ≤13 là số nhỏ và số lẻ, ví dụ: 13 (lẻ nhỏ) 12 (chẵn nhỏ)<br>Luật chơi:<br>1. Số tiền doanh thu bằng một lần số tiền gốc trước khi có thể thực hiện rút tiền. <br>2. Cấm sử dụng cùng một số hoặc các phương pháp khác để tiến hành so khớp chẵn và lẻ. <br>3. Nghiêm cấm việc cố ý lấy tiền thưởng. <br>4. Thành viên được nâng cấp cần hoàn thành doanh thu tương ứng trước khi có thể rút tiền mặt. <br>5. Để nhận được tiền thưởng qua đêm, bạn cần hoàn thành gấp đôi doanh thu và có thể rút tiền sau 24 giờ. <br>6. Sau khi đặt hạn ngạch thành viên, kênh rút tiền sẽ tạm thời bị đóng và việc rút tiền chỉ có thể được tiếp tục sau khi chương trình khuyến mãi thành công. <br>[Lưu ý: Quyền giải thích cuối cùng thuộc về nền tảng này~]<br>'
  }
export default en;