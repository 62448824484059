import { createI18n } from 'vue-i18n'
import zh from './config/zh'
import tw from './config/tw'
import en from './config/en'
import vi from './config/vi'
import ru from './config/ru'
import my from './config/my'
import ja from './config/ja'
import ko from './config/ko'
console.log(createI18n)
const i18n  = createI18n({
    legacy: false, // composition API
    globalInjection:true,
    locale:localStorage.getItem('locale')||'en',
    
    messages:{
        zh,
        tw,
		en,
        vi,
        ru,
        my,
        ko,
        ja
    },
    silentTranslationWarn: true,
    missingWarn: false, 
    fallbackWarn: false,
 })
 export default i18n;