const ja={
    jiantizhongwen: "英語",
    xianlu: "ライン",
    dianwei: "ポイント",
    wodezichan: "私の資産",
    touzikuaixun: "投資ニュース",
    jiaoyiyonghu: "ユーザー",
    jiaoyipinglei: "カテゴリー",
    danlunhuoli: "利益",
    jiaoyiriqi: "日付",
    dengluhouchakan: "ログイン後に見る",
    shouye: "家",
    jiaoyidating: "ホール",
    yucun: "プリストア",
    zoushi: "傾向",
    wode: "私の",
    zuiditouzi: "最低投資額",
    zuidijinru: "最小エントリー",
    jichulicai: "基本的な融資",
    zishenlicai: "シニアファイナンス",
    dashilicai: "マスターファイナンス",
    zhuanjialicai: "専門家による融資",
    基础理财: "基本的な融資",
    资深理财: "シニアファイナンス",
    大师理财: "マスターファイナンス",
    专家理财: "専門家による融資",
    huiyuanquanyi: "会員特典",
    zuoriyingli: "昨日の利益",
    zongzichan: "総資産",
    chucunjin: "お金",
    jinriyingli: "今日の利益",
    zhuanchu: "撤回する",
    cunru: "デポジット",
    zhanghuxinxni: "口座情報",
    lishizhangdan: "賭けの記録",
    zijinminxi: "資金調達の詳細",
    renwuzhongxin: "ミッションセンター",
    xiaoxigonggao: "ニュース発表",
    tuijianfenxiang: "共有することをお勧めします",
    huodongzhongxin: "アクティビティセンター",
    jibenziliao: "基本情報",
    dengluzhanghao: "ログインアカウント",
    nicheng: "ニックネーム",
    shezhi: "設定",
    genggaixianlu: "乗り換えライン",
    guanyuwomen: "私たちについて",
    xitongshezhi: "システム設定",
    yuyanshezhi: "言語設定",
    tuichudenglu: "ログアウト",
    bangdingyinhangka: "銀行カードをバインドする",
    bangdingxuniqianbao: "仮想ウォレットをバインドする",
    anquanma: "セキュリティコード",
    xiugailenglumima: "ログインパスワードを変更する",
    yinhangkaguanli: "銀行カードの管理",
    tianjiayinhangka: "銀行カードを追加する",
    xuniqianbaoguanli: "仮想ウォレット管理",
    tianjiaxuniqianbao: "仮想ウォレットを追加する",
    chiakren: "カード所有者",
    yinhangkakahao: "銀行カード番号",
    yinhangmingcheng: "銀行名",
    yhkTps: "カード所有者自身の銀行カードをバインドしてください",
    nixiqianbaodizhi: "ウォレットアドレス",
    xuniqianbaoleixing: "財布の種類",
    xnqbTps: "注意: USDT アドレス タイプ (trc20、erc20、omni など) を入力してください。",
    plchikaren: "カード所有者を入力してください",
    plkahao: "カード所有者のカード番号を入力してください",
    plyinhangmingcheng: "銀行名を入力してください",
    plqbdz: "仮想ウォレットのアドレスを入力してください",
    plqblx: "仮想ウォレットのタイプを入力してください",
    shezhianquanma: "セキュリティコードを設定する",
    yuananquanma: "オリジナルのセキュリティコード",
    xinanquanma: "新しいセキュリティコード",
    planquanma: "元のセキュリティコードを入力してください",
    plxinanquanma: "新しいセキュリティコードを入力してください",
    queren: "確認する",
    quxiao: "キャンセル",
    anquanmaTps: "銀行カードのパスワードとは異なるセキュリティコードを設定してください",
    anquanmaTpsTow: "お客様各位、このセキュリティ コードは送金時のパスワードです。セキュリティ上の理由から、ログインパスワードと同じものを使用しないようにしてください。",
    xiugaidenglu: "ログインパスワードを変更する",
    jiumima: "以前のパスワード",
    xinmima: "新しいパスワード",
    querenmima: "パスワードを認証する",
    pljiumima: "古いパスワードを入力してください",
    plxinmima: "新しいパスワードを入力してください",
    plquerenmima: "パスワードをもう一度入力してください",
    wancheng: "完了",
    kaishitouzi: "投資を始める",
    kaiTps: "現在の回線が利用できない場合は、他の回線に切り替えてみてください",
    zuiyou: "最適な",
    dangaqianxianlu: "現在の行",
    dangqianbanben: "現行版",
    banquansuoyou: "著作権",
    yinglizhendong: "収益ショック",
    gensuixitong: "デフォルト",
    zhuanchujine: "額",
    plzhuanchu: "送金額を入力してください",
    zhuanchuTps: "ヒント: ご質問がある場合は、プラットフォームのカスタマー サービスにお問い合わせください。",
    xuanzeyh: "引き出し用の銀行カードを選択してください",
    xuanzehb: "出金用の仮想ウォレットを選択してください",
    tijiaoshenqing: "申請書を提出する",
    shuruanquanma: "セキュリティコードを入力してください",
    xianshangcunru: "オンライン入金",
    saomacunru: "USDT",
    wangyincunru: "銀行預金",
    shijian: "時間",
    upshijian: "開始時間",
    downshijian: "終了時間",
    wuTps: "データがありません ~",
    jiazaicx: "リロード",
    di: "期間",
    lun: " ",
    weiyingli: "採算が合わない",
    yishouli: "承認されました",
    zhuangtai: "状態",
    chongzhi: "リセット",
    riqi: "日付",
    CapActive: {
        chongzhi: "リチャージ",
        tixian: "出金",
        goumai: "購入",
        yingli: "利益",
        zengsong: "譲渡",
        weitongguo: "通過しなかった",
        yitongguo: "合格",
        yijujue: "拒否"
      },
    zhanneixiaoxi: "情報",
    pingtaigonggao: "発表",
    fuzhilanjie: "リンクをコピーする",
    denglu: "ログイン",
    zhuce: "登録する",
    jizhumima: "覚えて",
    wangjimima: "パスワードをお忘れですか",
    youkedenglu: "訪問者ログイン",
    zhucezhanghao: "アカウント登録",
    plusername: "ユーザー名を入力してください",
    plpassword: "パスワードを入力してください",
    wanchengzhuce: "完了",
    yiyouzhanghao: "アカウントを持っています",
    qingdenglu: "サインインしてください",
    remenhuati: "注目の話題",
    news1: "オリジナル",
    news2: "推薦する",
    news3: "資産",
    renliulan: "ブラウズ",
    hezhi: "そして価値観",
    lunshu: "期間",
    做多: "多くの",
    做空: "短い",
    多单: "独身者が多い",
    多双: "多くのダブル",
    空单: "ショートシングル",
    空双: "ショートダブル",
    平单: "フラットシングル",
    平双: "フラットダブル",
    极阴: "陰極",
    极阳: "アノード",
    qi: " ",
    juli: "距離",
    lunjiezhi: "終わり",
    yifengpan: "賭けをやめる",
    yiguanbi: "閉まっている",
    yonghu: "ユーザー",
    danlunjiaoyi: "貿易",
    caozuo: "操作する",
    pljoin: "チャット ルームに正常に参加しました",
    pltingshou: "製品は製造中止となりました",
    gengou: "フォローする",
    quedinggengou: "購入をフォローしてもよろしいですか?",
    wanjia: "プレーヤー",
    leibie: "カテゴリー",
    jine: "額",
    gouru: "買う",
    zanweigouru: "まだ購入していません",
    qigou: "購入",
    gourushuliang: "購入数量",
    changgui: "従来の",
    shuzi: "番号",
    yinglihezhi: "利益と価値",
    shouyijieshao: "所得",
    lijicunru: "デポジット",
    kaihaojieguo: "記録",
    kaihaoqushi: "傾向",
    chanpingshuoming: "説明",
    qingchuhuancun: "キャッシュの消去",
    youke: "ビジター",
    gongxi: "おめでとう",
    zai: "の中に",
    yingli: "利益",
    xiadanchenggong: "正常に注文されました",
    jiazaizhong: "読み込み中...",
    guanbitg: "閉店しました、買うのやめてください",
    xiangoudl: "現在のラウンドのみをフォローする",
    liaotiantip: "システムは禁止されています、購入することを選択してください",
    tishi: "ヒント",
    zhidao: "わかりました",
    zhuanchusb: "転出に失敗しました",
    zhuanchusbs: "残高不足のため送金できませんでした",
    buyxq: "購入詳細",
    orderNo: "請求書番号",
    plan_name: "ホール情報",
    buyxx: "購入情報",
    haoxx: "番号選択の詳細",
    buysj: "時間を買う",
    yilou: "ない",
    fzjqb: "クリップボードにコピーされました",
    chanpin1: "販売時間：3.5分ごとに1ラウンド、同日午前10時に開店し、翌日午前2時に閉店します。",
    chanpin2: "各ラウンドには 0 から 9 までの 3 つの数字があります。 3 つの数値の合計が最終結果となります。結果に投資できます: メニー、ショート、フラット シングル、フラット ダブル、メニー シングル、メニー ダブル、ショート シングル、ショート ダブル、カソード、アノード、および番号。",
    Tips: {
        wjmm: "パスワードを忘れた場合は、カスタマーサービスに連絡して変更してください!",
        dlcg: "ログイン成功",
        tjcg: "送信成功",
        zanwu: "データなし",
        buyizhi: "2 つのパスワードが一致しません",
        zcbz: "資産が不足しています。ご入金ください",
        pltxje: "出金金額を入力してください",
        zdtx: "最低出金額 100",
        youke: "観光客の皆様へ 正会員にご登録の上、ご利用ください。 ",
        szcg: "正常に設定されました"
      },
    kefu: "顧客サービス",
    chongzhix: "リチャージ",
    game: "ゲームVIP",
    trading: "トレーディングフロア",
    putongting: "一般ホール",
    zhongjiting: "中ホール",
    gaojiting: "シニアホール",
    youxiwanfa: "ゲームプレイ",
    yuex: "バランス",
    chexiao: "取り消す",
    chexiaochenggong: "正常にキャンセルされました",
    daxiaodanshuang: "シングルサイズとダブルサイズ",
    shuzitouzhu: "デジタルベッティング",
    teshutoufa: "特殊な投資手法",
    qishu: "期間",
    zonghe: "和",
    jieguo: "結果",
    dadan: "大きな注文",
    xiaodan: "少量注文",
    dashuang: "大双",
    xiaoshuang: "シャオシュアン",
    dax: "大きい",
    xiaox: "小さい",
    dan: "シングル",
    shuang: "ダブル",
    線上充值聯繫在線客服: "オンラインリチャージ連絡オンラインサービス",
    在線客服: "オンラインサービス",
    咨询: "相談する",
    gameinfo:'<span style="font-size:18px; font-weight:700; text-align:center; display:block;">ゲームプレイ</span>0 ～ 13 は小さく、14 ～ 27 は大きく<br>奇数と 偶数:<br>引かれた数字が偶数の場合、それは偶数と呼ばれます。たとえば、0、2、4、6、8、10<br>発行される数字は奇数です。たとえば、1 、3、5、7、9、11<br>ビッグ シングルとビッグ イーブン:<br>数字 ≥14 はビッグで偶数です。例: 14 (ビッグ イーブン) 15 (ビッグ 奇数)<br>スモール シングルとスモール ダブル:<br>引かれた数字 ≤13 は小さくて奇数です。例: 13 (小さい奇数) 12 (小さい偶数)<br>ゲーム ルール:<br>1. 取引金額は、引き出しが可能になるまでの元本の 1 倍です。 <br>2. 同じ数字やその他の方法で奇数と偶数のマッチングを行うことは禁止されています。 <br>3. 悪意を持ってボーナスを取得することは固く禁止されています。 <br>4. アップグレードされたメンバーは、現金を引き出す前に、対応する取引を完了する必要があります。 <br>5. オーバーナイトボーナスを受け取るには、売上高の 2 倍を完了する必要があり、24 時間後に引き出すことができます。 <br>6. メンバーシップ割り当てを予約した後、引き出しチャネルは一時的に閉鎖され、プロモーションが成功した後にのみ引き出しを再開できます。 <br>[注: 最終的な解釈権はこのプラットフォームに属します~]<br>'
  }
export default ja;