const ko={
    jiantizhongwen: "영어",
    xianlu: "선",
    dianwei: "가리키다",
    wodezichan: "내 자산",
    touzikuaixun: "투자뉴스",
    jiaoyiyonghu: "사용자",
    jiaoyipinglei: "범주",
    danlunhuoli: "이익",
    jiaoyiriqi: "날짜",
    dengluhouchakan: "로그인 후 보기",
    shouye: "집",
    jiaoyidating: "홀",
    yucun: "프리스토어",
    zoushi: "경향",
    wode: "내 거",
    zuiditouzi: "최소 투자",
    zuidijinru: "최소 입장",
    jichulicai: "기본금융",
    zishenlicai: "시니어 파이낸싱",
    dashilicai: "마스터 파이낸싱",
    zhuanjialicai: "전문가 금융",
    基础理财: "기본금융",
    资深理财: "시니어 파이낸싱",
    大师理财: "마스터 파이낸싱",
    专家理财: "전문가 금융",
    huiyuanquanyi: "회원 혜택",
    zuoriyingli: "어제 이익",
    zongzichan: "총 자산",
    chucunjin: "돈",
    jinriyingli: "오늘의 이익",
    zhuanchu: "철회하다",
    cunru: "보증금",
    zhanghuxinxni: "계정 정보",
    lishizhangdan: "베팅 기록",
    zijinminxi: "자금 세부정보",
    renwuzhongxin: "선교 센터",
    xiaoxigonggao: "뉴스 발표",
    tuijianfenxiang: "공유 추천",
    huodongzhongxin: "활동 센터",
    jibenziliao: "기본 정보",
    dengluzhanghao: "로그인 계정",
    nicheng: "닉네임",
    shezhi: "설정",
    genggaixianlu: "줄 바꾸기",
    guanyuwomen: "회사 소개",
    xitongshezhi: "환경 설정",
    yuyanshezhi: "언어 설정",
    tuichudenglu: "로그 아웃",
    bangdingyinhangka: "은행 카드 바인딩",
    bangdingxuniqianbao: "가상 지갑 바인딩",
    anquanma: "보안 코드",
    xiugailenglumima: "로그인 비밀번호 수정",
    yinhangkaguanli: "은행 카드 관리",
    tianjiayinhangka: "은행 카드 추가",
    xuniqianbaoguanli: "가상 지갑 관리",
    tianjiaxuniqianbao: "가상 지갑 추가",
    chiakren: "카드홀더",
    yinhangkakahao: "은행 카드 번호",
    yinhangmingcheng: "은행 이름",
    yhkTps: "카드 소지자의 은행 카드를 묶어주세요.",
    nixiqianbaodizhi: "지갑 주소",
    xuniqianbaoleixing: "지갑 유형",
    xnqbTps: "알림: trc20, erc20, omni와 같은 USDT 주소 유형을 입력하세요.",
    plchikaren: "카드 소지자를 입력하세요.",
    plkahao: "카드 소지자의 카드 번호를 입력하세요.",
    plyinhangmingcheng: "은행명을 입력해주세요",
    plqbdz: "가상 지갑 주소를 입력해주세요",
    plqblx: "가상 지갑 유형을 입력하세요.",
    shezhianquanma: "보안 코드 설정",
    yuananquanma: "원래 보안 코드",
    xinanquanma: "새로운 보안 코드",
    planquanma: "원래 보안 코드를 입력하세요",
    plxinanquanma: "새로운 보안 코드를 입력해주세요",
    queren: "확인하다",
    quxiao: "취소",
    anquanmaTps: "은행카드 비밀번호와 동일하지 않은 보안코드를 설정해주세요.",
    anquanmaTpsTow: "고객님, 이 보안코드는 이체하실 때 사용되는 비밀번호입니다. 보안상의 이유로 로그인 비밀번호와 동일하지 않도록 하십시오.",
    xiugaidenglu: "로그인 비밀번호 수정",
    jiumima: "기존 비밀번호",
    xinmima: "새 비밀번호",
    querenmima: "비밀번호 확인",
    pljiumima: "이전 비밀번호를 입력해주세요",
    plxinmima: "새로운 비밀번호를 입력해주세요",
    plquerenmima: "비밀번호를 다시 입력해주세요",
    wancheng: "완벽한",
    kaishitouzi: "투자 시작",
    kaiTps: "현재 회선을 사용할 수 없으면 다른 회선으로 전환해 보세요.",
    zuiyou: "최적",
    dangaqianxianlu: "현재 라인",
    dangqianbanben: "현재 버전",
    banquansuoyou: "저작권",
    yinglizhendong: "어닝쇼크",
    gensuixitong: "기본",
    zhuanchujine: "양",
    plzhuanchu: "이체금액을 입력해주세요",
    zhuanchuTps: "팁: 궁금한 사항이 있으면 플랫폼 고객 서비스에 문의하세요.",
    xuanzeyh: "출금할 은행카드를 선택해주세요",
    xuanzehb: "출금할 가상 지갑을 선택해주세요",
    tijiaoshenqing: "지원서 제출",
    shuruanquanma: "보안코드를 입력해주세요",
    xianshangcunru: "온라인 입금",
    saomacunru: "USDT",
    wangyincunru: "은행예금",
    shijian: "시간",
    upshijian: "시작 시간",
    downshijian: "종료 시간",
    wuTps: "데이터가 없습니다 ~",
    jiazaicx: "새로고침",
    di: "기간",
    lun: " ",
    weiyingli: "무익한",
    yishouli: "수락됨",
    zhuangtai: "상태",
    chongzhi: "초기화",
    riqi: "날짜",
    CapActive: {
        chongzhi: "리챠지",
        tixian: "출금",
        goumai: "購入",
        yingli: "利益",
        zengsong: "譲渡",
        weitongguo: "퉁過しなた",
        yitongguo: "합치다",
        yijujue: "拒否"
      },
    zhanneixiaoxi: "정보",
    pingtaigonggao: "발표",
    fuzhilanjie: "링크 복사",
    denglu: "로그인",
    zhuce: "등록하다",
    jizhumima: "기억하다",
    wangjimima: "비밀번호를 잊으 셨나요",
    youkedenglu: "방문자 로그인",
    zhucezhanghao: "계정 등록",
    plusername: "사용자 이름을 입력하세요",
    plpassword: "비밀번호를 입력 해주세요",
    wanchengzhuce: "완벽한",
    yiyouzhanghao: "계정을 갖고있다",
    qingdenglu: "로그인 해주세요",
    remenhuati: "화제",
    news1: "원래의",
    news2: "추천하다",
    news3: "유산",
    renliulan: "검색",
    hezhi: "그리고 가치",
    lunshu: "기간",
    做多: "많은",
    做空: "짧은",
    多单: "많은 싱글",
    多双: "많은 더블",
    空单: "쇼트 싱글",
    空双: "쇼트 더블",
    平单: "플랫 싱글",
    平双: "플랫 더블",
    极阴: "음극",
    极阳: "양극",
    qi: " ",
    juli: "거리",
    lunjiezhi: "끝",
    yifengpan: "베팅 중지",
    yiguanbi: "닫은",
    yonghu: "사용자",
    danlunjiaoyi: "거래",
    caozuo: "작동하다",
    pljoin: "채팅방에 성공적으로 참여했습니다",
    pltingshou: "제품이 단종되었습니다",
    gengou: "따르다",
    quedinggengou: "구매를 따르시겠습니까?",
    wanjia: "플레이어",
    leibie: "범주",
    jine: "양",
    gouru: "구입하다",
    zanweigouru: "아직 구매하지 않음",
    qigou: "구입",
    gourushuliang: "구매 수량",
    changgui: "전통적인",
    shuzi: "숫자",
    yinglihezhi: "이익과 가치",
    shouyijieshao: "소득",
    lijicunru: "보증금",
    kaihaojieguo: "기록",
    kaihaoqushi: "경향",
    chanpingshuoming: "설명",
    qingchuhuancun: "캐시 지우기",
    youke: "방문객",
    gongxi: "축하하다",
    zai: "에서",
    yingli: "이익",
    xiadanchenggong: "성공적으로 주문했습니다",
    jiazaizhong: "로드 중...",
    guanbitg: "문 닫았어, 구매를 중단해",
    xiangoudl: "현재 라운드만 팔로우",
    liaotiantip: "시스템이 금지되었습니다. 구매를 선택하세요.",
    tishi: "팁",
    zhidao: "좋아요",
    zhuanchusb: "외부로 이전하지 못했습니다.",
    zhuanchusbs: "잔액 부족, 이체 실패",
    buyxq: "구매내역",
    orderNo: "빌 번호",
    plan_name: "홀정보",
    buyxx: "구매정보",
    haoxx: "번호 선택 세부정보",
    buysj: "구매 시간",
    yilou: "없어진",
    fzjqb: "클립보드에 복사됨",
    chanpin1: "판매시간 : 3.5분 마다 한 라운드로 당일 오전 10시에 오픈하고 다음날 오전 2시에 마감합니다.",
    chanpin2: "각 라운드에는 0부터 9까지 3개의 숫자가 있습니다. 3개 숫자의 합이 최종 결과입니다. Many, Short, Flat Single, Flat Double, Many Single, Many Double, Short Single, Short Double, Cathode, Anode 및 Number의 결과에 투자할 수 있습니다.",
    Tips: {
        wjmm: "당신을 선택하십시오. 수정하려면 고객 서비스에 문의하세요!",
        dlcg: "로그인 성공",
        tjcg: "성공적으로 제출했습니다",
        zanwu: "데이터 없음",
        buyizhi: "두 번째 찾기가 일치하지 않습니다.",
        zcbz: "자산이 없습니다. 해주세요",
        pltxje: "출금금액을 입력하세요",
        zdtx: "소수 출금 금액 100",
        youke: "여러분, 정회원으로 등록하시길 바랍니다.",
        szcg: "성공적으로 설정되었습니다"
      },
    kefu: "고객 서비스",
    chongzhix: "재충전",
    game: "게임 VIP",
    trading: "거래소",
    putongting: "일반 홀",
    zhongjiting: "중급 홀",
    gaojiting: "노인관",
    youxiwanfa: "게임플레이",
    yuex: "균형",
    chexiao: "취소",
    chexiaochenggong: "성공적으로 취소되었습니다",
    daxiaodanshuang: "싱글 및 더블 사이즈",
    shuzitouzhu: "디지털 베팅",
    teshutoufa: "특별한 투자방법",
    qishu: "기간",
    zonghe: "합집합",
    jieguo: "결과",
    dadan: "큰 주문",
    xiaodan: "소액 주문",
    dashuang: "다솽",
    xiaoshuang: "샤오솽",
    dax: "큰",
    xiaox: "작은",
    dan: "하나의",
    shuang: "더블",
    線上充值聯繫在線客服: "온라인 충전 문의 온라인 서비스",
    在線客服: "온라인 서비스",
    咨询: "찾다",
    gameinfo:'<span style="font-size:18px;font-weight:700; text-align:center; display:block;">게임 플레이</span>0-13은 작고, 14-27은 큽니다.<br>이상하고 짝수:<br>추첨된 숫자가 짝수인 경우 짝수라고 합니다. 예: 0,2,4,6,8,10<br>발행된 숫자는 홀수입니다. 예: 1 ,3,5,7,9,11<br>큰 단수 및 큰 짝수:<br>숫자 ≥14는 큰 짝수입니다. 예: 14(큰 짝수) 15(큰 홀수)<br>작은 단일 및 소형 이중:<br>13 이하로 추첨된 숫자는 소형 및 홀수입니다. 예: 13(작은 홀수) 12(작은 짝수)<br>게임 규칙:<br>1. 회전율은 출금 전 원금의 1배입니다. <br>2. 홀수 및 짝수 매칭을 수행하기 위해 동일한 수 또는 기타 방법을 사용하는 것은 금지되어 있습니다. <br>3. 악의적으로 보너스를 획득하는 것은 엄격히 금지됩니다. <br>4. 업그레이드된 회원은 현금을 인출하기 전에 해당 매출을 완료해야 합니다. <br>5. 익일 보너스를 받으려면 매출을 2배로 완료해야 하며, 24시간 후에 출금할 수 있습니다. <br>6. 회원 할당량 예약 후에는 탈퇴 채널이 일시적으로 폐쇄되며, 프로모션이 성공한 후에만 탈퇴가 재개될 수 있습니다. <br>[참고: 최종 해석권은 본 플랫폼에 있습니다~]<br>'
  }
export default ko;