const my={
    jiantizhongwen: "Inggeris",
    xianlu: "Talian",
    dianwei: "titik",
    wodezichan: "Aset saya",
    touzikuaixun: "Berita Pelaburan",
    jiaoyiyonghu: "pengguna",
    jiaoyipinglei: "kategori",
    danlunhuoli: "Untung",
    jiaoyiriqi: "Tarikh",
    dengluhouchakan: "Lihat selepas log masuk",
    shouye: "Rumah",
    jiaoyidating: "dewan",
    yucun: "Prasimpan",
    zoushi: "Trend",
    wode: "saya",
    zuiditouzi: "Pelaburan minimum",
    zuidijinru: "Kemasukan minimum",
    jichulicai: "Pembiayaan Asas",
    zishenlicai: "Pembiayaan Kanan",
    dashilicai: "Pembiayaan Induk",
    zhuanjialicai: "Pembiayaan Pakar",
    基础理财: "Pembiayaan Asas",
    资深理财: "Pembiayaan Kanan",
    大师理财: "Pembiayaan Induk",
    专家理财: "Pembiayaan Pakar",
    huiyuanquanyi: "Faedah ahli",
    zuoriyingli: "Untung semalam",
    zongzichan: "Jumlah aset",
    chucunjin: "Wang",
    jinriyingli: "Untung hari ini",
    zhuanchu: "tarik diri",
    cunru: "Deposit",
    zhanghuxinxni: "Maklumat Akaun",
    lishizhangdan: "Rekod pertaruhan",
    zijinminxi: "Butiran Pembiayaan",
    renwuzhongxin: "Pusat misi",
    xiaoxigonggao: "Pengumuman berita",
    tuijianfenxiang: "Disyorkan untuk dikongsi",
    huodongzhongxin: "Pusat Aktiviti",
    jibenziliao: "Maklumat asas",
    dengluzhanghao: "Log masuk akaun",
    nicheng: "nama panggilan",
    shezhi: "tetapan",
    genggaixianlu: "Tukar baris",
    guanyuwomen: "Tentang kita",
    xitongshezhi: "Tetapan sistem",
    yuyanshezhi: "Tetapan bahasa",
    tuichudenglu: "Log keluar",
    bangdingyinhangka: "Ikat kad bank",
    bangdingxuniqianbao: "Ikat dompet maya",
    anquanma: "Kod keselamatan",
    xiugailenglumima: "Ubah suai kata laluan log masuk",
    yinhangkaguanli: "Pengurusan kad bank",
    tianjiayinhangka: "Tambah kad bank",
    xuniqianbaoguanli: "Pengurusan dompet maya",
    tianjiaxuniqianbao: "Tambah dompet maya",
    chiakren: "Pemegang kad",
    yinhangkakahao: "Nombor kad bank",
    yinhangmingcheng: "nama bank",
    yhkTps: "Sila ikat kad bank pemegang kad sendiri",
    nixiqianbaodizhi: "Alamat dompet",
    xuniqianbaoleixing: "Jenis Dompet",
    xnqbTps: "Peringatan: Sila isikan jenis alamat USDT, seperti: trc20, erc20, omni.",
    plchikaren: "Sila masukkan pemegang kad",
    plkahao: "Sila masukkan nombor kad pemegang kad",
    plyinhangmingcheng: "Sila masukkan nama bank",
    plqbdz: "Sila masukkan alamat dompet maya",
    plqblx: "Sila masukkan jenis dompet maya",
    shezhianquanma: "Tetapkan kod keselamatan",
    yuananquanma: "Kod keselamatan asal",
    xinanquanma: "Kod keselamatan baharu",
    planquanma: "Sila masukkan kod keselamatan asal",
    plxinanquanma: "Sila masukkan kod keselamatan baharu",
    queren: "sahkan",
    quxiao: "Batal",
    anquanmaTps: "Sila tetapkan kod keselamatan, tidak sama dengan kata laluan kad bank",
    anquanmaTpsTow: "Pelanggan yang dihormati, kod keselamatan ini ialah kata laluan apabila anda berpindah keluar. Atas sebab keselamatan, sila cuba untuk tidak sama dengan kata laluan log masuk",
    xiugaidenglu: "Ubah suai kata laluan log masuk",
    jiumima: "Kata laluan lama",
    xinmima: "Kata laluan baharu",
    querenmima: "Sahkan Kata Laluan",
    pljiumima: "Sila masukkan kata laluan lama",
    plxinmima: "Sila masukkan kata laluan baharu",
    plquerenmima: "Sila masukkan kata laluan sekali lagi",
    wancheng: "lengkap",
    kaishitouzi: "Mulakan pelaburan",
    kaiTps: "Apabila talian semasa tidak tersedia, cuba tukar ke talian lain",
    zuiyou: "Optimum",
    dangaqianxianlu: "Talian semasa",
    dangqianbanben: "Versi terkini",
    banquansuoyou: "hak cipta",
    yinglizhendong: "Kejutan pendapatan",
    gensuixitong: "Lalai",
    zhuanchujine: "Jumlah",
    plzhuanchu: "Sila masukkan amaun pindahan",
    zhuanchuTps: "Petua: Jika anda mempunyai sebarang pertanyaan, sila hubungi perkhidmatan pelanggan platform.",
    xuanzeyh: "Sila pilih kad bank untuk pengeluaran",
    xuanzehb: "Sila pilih dompet maya untuk pengeluaran",
    tijiaoshenqing: "Hantar permohonan",
    shuruanquanma: "Sila masukkan kod keselamatan",
    xianshangcunru: "Deposit dalam talian",
    saomacunru: "USDT",
    wangyincunru: "Deposit perbankan",
    shijian: "Masa",
    upshijian: "Masa mula",
    downshijian: "Masa tamat",
    wuTps: "Tiada data ~",
    jiazaicx: "Tambah nilai",
    di: "Tempoh",
    lun: " ",
    weiyingli: "Tidak menguntungkan",
    yishouli: "Diterima",
    zhuangtai: "Status",
    chongzhi: "Tetapkan semula",
    riqi: "Tarikh",
    CapActive: {
        chongzhi: "Caj semula",
        tixian: "Tarik balik",
        goumai: "Beli",
        yingli: "Untung",
        zengsong: "Hadiah",
        weitongguo: "tak lulus",
        yitongguo: "Lulus",
        yijujue: "Ditolak"
      },
    zhanneixiaoxi: "Maklumat",
    pingtaigonggao: "Pengumuman",
    fuzhilanjie: "Salin pautan",
    denglu: "Log masuk",
    zhuce: "Daftar",
    jizhumima: "Ingat",
    wangjimima: "Lupa kata laluan",
    youkedenglu: "Log masuk pelawat",
    zhucezhanghao: "Daftar akaun",
    plusername: "Sila masukkan gabungan 6 hingga 16 digit huruf dan nombor",
    plpassword: "Sila masukkan kata laluan",
    wanchengzhuce: "lengkap",
    yiyouzhanghao: "Mempunyai akaun",
    qingdenglu: "Sila log masuk",
    remenhuati: "Topik hangat",
    news1: "Asal",
    news2: "Mengesyorkan",
    news3: "Aset",
    renliulan: "semak imbas",
    hezhi: "Dan nilai",
    lunshu: "Tempoh",
    做多: "banyak",
    做空: "Pendek",
    多单: "Ramai Bujang",
    多双: "Banyak Berganda",
    空单: "Single Pendek",
    空双: "Berganda Pendek",
    平单: "Bujang Rata",
    平双: "Flat Double",
    极阴: "Katod",
    极阳: "Anod",
    qi: " ",
    juli: "jarak",
    lunjiezhi: "tamat",
    yifengpan: "Berhenti bertaruh",
    yiguanbi: "tertutup",
    yonghu: "pengguna",
    danlunjiaoyi: "Berdagang",
    caozuo: "Beroperasi",
    pljoin: "Berjaya menyertai bilik sembang",
    pltingshou: "Produk telah dihentikan",
    gengou: "Ikut",
    quedinggengou: "Adakah anda pasti mengikuti pembelian?",
    wanjia: "pemain",
    leibie: "kategori",
    jine: "jumlah",
    gouru: "Beli",
    zanweigouru: "Belum dibeli",
    qigou: "pembelian",
    gourushuliang: "Kuantiti belian",
    changgui: "konvensional",
    shuzi: "Nombor",
    yinglihezhi: "Keuntungan dan nilai",
    shouyijieshao: "pendapatan",
    lijicunru: "Deposit",
    kaihaojieguo: "Rekod",
    kaihaoqushi: "Trend",
    chanpingshuoming: "Penerangan",
    qingchuhuancun: "kosongkan cache",
    youke: "Pelawat",
    gongxi: "Tahniah kepada",
    zai: "di dalam",
    yingli: "keuntungan",
    xiadanchenggong: "berjaya dipesan",
    jiazaizhong: "Memuatkan...",
    guanbitg: "tutup, berhenti membeli",
    xiangoudl: "Hanya ikut pusingan semasa",
    liaotiantip: "Sistem telah diharamkan, sila pilih untuk membeli",
    tishi: "Petua",
    zhidao: "okey",
    zhuanchusb: "Gagal memindahkan keluar",
    zhuanchusbs: "Baki tidak mencukupi, pemindahan keluar gagal",
    buyxq: "Butiran pembelian",
    orderNo: "Bil No.",
    plan_name: "Maklumat dewan",
    buyxx: "Beli maklumat",
    haoxx: "Butiran pemilihan nombor",
    buysj: "Beli masa",
    yilou: "hilang",
    fzjqb: "Disalin ke papan keratan",
    chanpin1: "Masa jualan: setiap 3.5 minit adalah pusingan, dibuka pada 10:00AM pada hari yang sama dan ditutup pada 02:00AM keesokan harinya.",
    chanpin2: "Terdapat 3 nombor dari 0 hingga 9 dalam setiap pusingan. Jumlah 3 nombor adalah hasil akhir. Anda boleh melabur dalam hasil: Many, Short, Flat Single, Flat Double, Many Single, Many Double, Short Single, Short Double, Katod, Anod dan nombor.",
    Tips: {
        wjmm: "Terlupa kata laluan anda, sila hubungi perkhidmatan pelanggan untuk mengubah suainya!",
        dlcg: "Log masuk berjaya",
        tjcg: "Berjaya dihantar",
        zanwu: "Tiada data",
        buyizhi: "Kedua-dua kata laluan tidak konsisten",
        zcbz: "Aset anda tidak mencukupi, sila deposit",
        pltxje: "Sila masukkan jumlah pengeluaran",
        zdtx: "Pengeluaran minimum 100",
        youke: "Pelancong yang dihormati: Sila daftar sebagai ahli penuh dan beroperasi.",
        szcg: "berjaya ditetapkan"
      },
    kefu: "khidmat Pelanggan",
    chongzhix: "Isi semula",
    game: "Permainan VIP",
    trading: "Lantai Perdagangan",
    putongting: "Dewan biasa",
    zhongjiting: "Dewan Pertengahan",
    gaojiting: "dewan kanan",
    youxiwanfa: "Permainan",
    yuex: "Seimbang",
    chexiao: "Membatalkan",
    chexiaochenggong: "Berjaya dibatalkan",
    daxiaodanshuang: "Saiz tunggal dan berganda",
    shuzitouzhu: "Pertaruhan digital",
    teshutoufa: "Kaedah pelaburan khas",
    qishu: "Tempoh",
    zonghe: "Jumlah",
    jieguo: "Hasilnya",
    dadan: "Pesanan besar",
    xiaodan: "Pesanan kecil",
    dashuang: "Da Shuang",
    xiaoshuang: "Xiao Shuang",
    dax: "Besar",
    xiaox: "Kecil",
    dan: "Bujang",
    shuang: "berganda",
    線上充值聯繫在線客服: "Perkhidmatan dalam talian hubungi cas semula dalam talian",
    在線客服: "perkhidmatan dalam talian",
    咨询: "berunding",
    gameinfo:'<span style="font-size:18px; font-weight:700; text-align:center; display:block;">Permainan</span>0-13 kecil, 14-27 besar<br>Geli dan Genap:<br>Jika nombor yang dilukis ialah nombor genap, ia dipanggil nombor genap, contohnya: 0,2,4,6,8,10<br>Nombor yang dikeluarkan ialah nombor ganjil, contohnya: 1 ,3,5,7,9,11<br>Perseorangan besar dan genap besar:<br>Nombor ≥14 ialah nombor besar dan genap, contohnya: 14 (genap besar) 15 (ganjil besar)<br>Kecil ganda tunggal dan kecil:<br>Nombor yang dilukis ≤13 adalah kecil dan ganjil, contohnya: 13 (ganjil kecil) 12 (genap kecil)<br>Peraturan permainan:<br>1. Amaun pusing ganti adalah satu kali daripada prinsipal sebelum pengeluaran boleh dibuat. <br>2. Dilarang menggunakan nombor yang sama atau kaedah lain untuk menjalankan padanan ganjil dan genap. <br>3. Dilarang sama sekali untuk mendapatkan bonus secara berniat jahat. <br>4. Ahli yang dinaik taraf perlu melengkapkan pusing ganti yang sepadan sebelum mereka boleh mengeluarkan wang tunai. <br>5. Untuk menerima bonus semalaman, anda perlu melengkapkan dua kali pusing ganti, dan anda boleh mengeluarkannya selepas 24 jam. <br>6. Selepas menempah kuota keahlian, saluran pengeluaran akan ditutup buat sementara waktu, dan pengeluaran boleh disambung semula hanya selepas promosi berjaya. <br>[Nota: Hak tafsiran terakhir adalah milik platform ini~]<br>'
  }
export default my;